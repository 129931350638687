<template>
    <div>
        <b-row>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Background <tooltip-with-image img="/img/bill/background.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorBackground" v-model="config.background"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Card Background <tooltip-with-image img="/img/bill/card_background.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorCard" v-model="config.card_background"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Other</h4>
                <b-form-group>
                    <label>Currency unit: <tooltip-with-image img="/img/bill/currency.png"/></label>
                    <b-form-input v-model="config.currency_unit" />
                </b-form-group>
                <b-form-group>
                    <label>Text color: <fa class="ml-1" icon="question-circle" v-b-tooltip="'Set color and opacity.'" /></label>
                    <ColorWithOpacitySelector v-model="config.text_color"/>
                </b-form-group>
                <b-form-group>
                    <label>Table border color: <fa class="ml-1" icon="question-circle" v-b-tooltip="'Set color and opacity.'" /></label>
                    <ColorWithOpacitySelector v-model="config.table_border_color"/>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import {BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default  {
        components: {
            TooltipWithImage,
            ColorWithOpacitySelector,
            BackgroundSelector,
            BFormGroup,
            BFormInput,
            BRow,
            BCol
        },
        data() {
            return {
                config: {
                    background: {},
                    card_background: {},
                    text_color: {
                        color: '#f1f1f1',
                        opacity: 1.0
                    },
                    table_border_color: {
                        color: '#f1f1f1',
                        opacity: 0.5
                    },
                    currency_unit: ''
                }
            }
        },
        methods: {
            getConfig() {
                return this.config
            },
            setConfig(config) {
                this.config = {...this.config, ...config}
            },
            valid() {
                if (!this.$refs.BackgroundSelectorBackground.valid()) return false
                else if  (!this.$refs.BackgroundSelectorCard.valid()) return false
                return true
            }
        }
    }

</script>
